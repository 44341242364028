.simplemde-container {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
    /* Enable scrolling if content overflows */
}

/* Custom width classes for different breakpoints */

@media (min-width: 375px) {

    /* Small screens and up */
    .w-xs-25 {
        width: 25%;
    }

    .w-xs-50 {
        width: 50%;
    }

    .w-xs-75 {
        width: 75%;
    }

    .w-xs-100 {
        width: 100%;
    }

    .w-xs-auto {
        width: 'auto!important';
    }
}

@media (min-width: 576px) {

    /* Small screens and up */
    .w-sm-25 {
        width: 25%;
    }

    .w-sm-50 {
        width: 50%;
    }

    .w-sm-75 {
        width: 75%;
    }

    .w-sm-100 {
        width: 100%;
    }

    .w-sm-auto {
        width: 'auto!important';
    }
}

@media (min-width: 768px) {

    /* Medium screens and up */
    .w-md-25 {
        width: 25%;
    }

    .w-md-50 {
        width: 50%;
    }

    .w-md-75 {
        width: 75%;
    }

    .w-md-100 {
        width: 100%;
    }

    .w-md-auto {
        width: 'auto!important';
    }
}

@media (min-width: 992px) {

    /* Large screens and up */
    .w-lg-25 {
        width: 25%;
    }

    .w-lg-50 {
        width: 50%;
    }

    .w-lg-75 {
        width: 75%;
    }

    .w-lg-100 {
        width: 100%;
    }

    .w-lg-auto {
        width: 'auto!important';
    }
}

@media (min-width: 1200px) {

    /* Extra large screens and up */
    .w-xl-25 {
        width: 25%;
    }

    .w-xl-50 {
        width: 50%;
    }

    .w-xl-75 {
        width: 75%;
    }

    .w-xl-100 {
        width: 100%;
    }

    .w-xl-auto {
        width: 'auto!important';
    }
}