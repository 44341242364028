body > div.rs-anim-fade.rs-anim-in.rs-picker-date-menu.rs-picker-menu.placement-bottom-start {
    z-index: 9999;
}

.datepicker-options{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-between;
    margin-left: 3rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.datepicker-options input {
    margin-top: 0.6rem;
}
